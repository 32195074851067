/* eslint-disable */

import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';

import ProductListQuery from 'Query/ProductList.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Query/Cart */
export class CartQuery extends SourceCartQuery {
    _getCartItemFields() {
        return [
            ...super._getCartItemFields(),
            'upsellProductSku'
        ];
    }
}

export default new CartQuery();