 /**
  * ScandiPWA - Progressive Web App for Magento
  *
  * Copyright © Scandiweb, Inc. All rights reserved.
  * See LICENSE for license details.
  *
  * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
  * @package scandipwa/base-theme
  * @link https://github.com/scandipwa/base-theme
  */
 export const aroundHeader = (args, callback, instance) => {


     let full_url = window.location.href;
     let split_url = full_url.split("?");
     if (split_url.length > 1) {
         let re_split_url = split_url[1].split("&");
         let tracker = ["pa-partnerid", "pacId"];
         let expDays = 30;
         let date = new Date();
         date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
         const expires = "expires=" + date.toUTCString();


         for (let i = 0; i < re_split_url.length; i++) {
             let split_now = re_split_url[i].split('=');
             if (tracker.includes(split_now[0])) {
                 let cName = split_now[0];
                 let cValue = split_now[1];
                 document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
             }
         }
     }




     return callback(...args);
 };

 export default {
     'Component/Header/Container': {
         'member-function': {
             componentDidMount: aroundHeader
         }
     }
    }