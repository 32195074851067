/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import '/home/malgodt-dev/webapps/malgodt-dev/releases/000000000001/src/localmodules/scandi-app/node_modules/@scandipwa/scandipwa-extensibility/runtime-helpers';
import "Util/Polyfill";
import "Style/main";

import PropTypes from "prop-types";
import { render } from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { pwaversion } from "./version.js";

import App from "Component/App";

PropTypes.string = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

//  Sentry.init({
//      dsn: "https://e04191c53f59405189da9e5a583d9d3d@o1153142.ingest.sentry.io/6263638",
//      integrations: [new BrowserTracing()],
//      tracesSampleRate: 0.3,
//    });

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        //  console.log(pwaversion);
        const swUrl = "/service-worker.js?v=" + pwaversion;
        navigator.serviceWorker
            .register(swUrl, { scope: "/" })
            .then(function (registration) {
                var serviceWorker;
                if (registration.installing) {
                    serviceWorker = registration.installing;
                    registration.installing.postMessage("SKIP_WAITING");
                    for (let regSW of registration) {
                        regSW.unregister();
                    }
                } else if (registration.waiting) {
                    serviceWorker = registration.waiting;
                    registration.waiting.postMessage("SKIP_WAITING");
                    for (let regSW of registration) {
                        regSW.unregister();
                    }
                } else if (registration.active) {
                    serviceWorker = registration.active;
                }
            });
    });
}

render(<App />, document.getElementById("root"));
