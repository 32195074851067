import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import React from "react";
import { appendWithStoreCode } from 'Util/Url';
import history from 'Util/History';

import {
  appId,
  apiKey,
  indexName,
  indexNameCMS,
  indexNameCategory
} from "./AlgoriaSearch.config";

const searchClient = algoliasearch(
  appId,
  apiKey
);
// let autocomplete;
class AutocompleteClass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteState: {},
      query: '',
    };
    this.autocomplete = createAutocomplete({
      onStateChange: this.onChange,
      getSources() {
        return [
          {
            sourceId: "products",
            getItems({ query }) {
              // console.log('getting query', query)
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                      numericFilters: [
                        'visibility_search = 1'
                      ]
                    }
                  },
                  {
                    indexName: indexNameCMS,
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>"
                    }
                  },
                  {
                    indexName: indexNameCategory,
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>"
                    }
                  }
                ]
              });
            },
            getItemUrl({ item }) {
              return item.url;
            }
          }
        ];
      }
    });
  }

  onSearchEnterPress = (e) => {
    if (e.key === 'Enter' && document.getElementById("product_auto").value !== '') {
      let search = document.querySelector(".aa-Input").value
      history.push(appendWithStoreCode(`/search/${search}`));
      setTimeout(async function () { document.querySelector(".closeserc").click(); }, 1000);
    }
  };


  onSearchAllPress = (e) => {
    let search = document.querySelector(".aa-Input").value
    history.push(appendWithStoreCode(`/search/${search}`));
    setTimeout(async function () { document.querySelector(".closeserc").click(); }, 1000);
  };

  onChange = ({ state }) => {
    let search = document.getElementById("product_auto").value;

    if (search) {
      document.getElementById("searchautocompleteresult").style.display = "block";
    } else {
      document.getElementById("searchautocompleteresult").style.display = "none";
    }

    if (document.getElementById("start_typing")) {
      if (search) {
        document.getElementById("start_typing").style.display = "none";
        document.getElementById("searchautocompleteresult").style.display = "block";
      } else {
        document.getElementById("start_typing").style.display = "block";
        document.getElementById("guidetitle").style.display = "none";
        document.getElementById("producttitle").style.display = "none";
        document.getElementById("seeallseach").style.display = "none";
        document.getElementById("searchautocompleteresult").style.display = "none";
      }
    }

    this.setState({ autocompleteState: state, query: state.query });
  }

  render() {
    const { autocompleteState } = this.state;
    return (
      <div className="aa-Autocomplete" {...this.autocomplete?.getRootProps({})}>
        <div className="aa-SrcIcon"></div>
        <form
          className="aa-Form"
          {...this.autocomplete?.getFormProps({
            inputElement: this.state.query
          })}
        >
          <div className="aa-InputWrapperPrefix">
            <label
              className="aa-Label"
              {...this.autocomplete?.getLabelProps({})}
            >
            </label>
          </div>
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              value={this.state.query}
              {...this.autocomplete?.getInputProps({})}
              onKeyDown={this.onSearchEnterPress}
              placeholder={__("Søg i alle produkter og sider her...")}
              id={"product_auto"}
            />
          </div>
        </form>
        <article aria-label="Search results" id="searchautocompleteresult" className="SearchOverlay-Results" {...this.autocomplete?.getPanelProps({})} style={{ display: 'none' }}>
          <div className="srcBarH12" id="producttitle" style={{ display: 'none' }}>Produkter</div>
          <ul>
            {autocompleteState.isOpen &&
              autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;
                console.log(items.length);
                {
                  if (items.length > 0) {

                    document.getElementById("searchautocompleteresult").style.display = "block";
                    document.getElementById("guidetitle").style.display = "block";
                    document.getElementById("producttitle").style.display = "block";
                    document.getElementById("seeallseach").style.display = "block";
                  } else {

                    document.getElementById("searchautocompleteresult").style.display = "none";
                    document.getElementById("guidetitle").style.display = "none";
                    document.getElementById("producttitle").style.display = "none";
                    document.getElementById("seeallseach").style.display = "none";
                    document.getElementById("searchautocompleteresult").style.display = "none";
                  }
                }

                return (
                  <div key={`source-${index}`} className="aa-Source">
                    {items.length > 0 && (
                      <ul
                        className="aa-List"
                        {...this.autocomplete?.getListProps()}
                      >
                        {items.map((item) => (
                          <li
                            key={item.objectID}
                            className="SearchItem"
                            {...this.autocomplete?.getItemProps({
                              item,
                              source
                            })}
                            style={{ display: item.__autocomplete_indexName == indexName ? "block" : 'none' }}
                          >
                            <a href={item.url} className=" SearchItem-Link">
                              <figure className="SearchItem-Wrapper">
                                <div className="Image Image_ratio_square Image_imageStatus_1 Image_hasSrc  "><img src={item.image_url} alt={item.name} loading="lazy" className="Image-Image" /></div>
                                <figcaption className="SearchItem-Content">
                                  <h4 className="SearchItem-Title SearchItem-Title_isLoaded">{item.name}</h4>
                                </figcaption>
                              </figure>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
          </ul>
          <>
            <div className="srcBarH1" id="guidetitle" style={{ display: 'none' }}>Guides / Sider / Kategorier</div>
          </>
          <ul>
            {autocompleteState.isOpen &&
              autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;

                return (
                  <div key={`source-${index}`} className="aa-Source guidslink">
                    {items.length > 0 && (
                      <ul
                        className="aa-List"
                        {...this.autocomplete?.getListProps()}
                      >
                        {items.map((item) => (
                          <li
                            key={item.objectID}
                            className="SearchItem"
                            {...this.autocomplete?.getItemProps({
                              item,
                              source
                            })}
                            style={{ display: item.__autocomplete_indexName == indexNameCMS ? "block" : 'none' }}
                          >
                            <a href={item.url} className=" SearchItem-Link ">
                              <h4>{item.name}</h4>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
          </ul>
          <ul>
            {autocompleteState.isOpen &&
              autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;

                return (
                  <div key={`source-${index}`} className="aa-Source guidslink">
                    {items.length > 0 && (
                      <ul
                        className="aa-List"
                        {...this.autocomplete?.getListProps()}
                      >
                        {items.map((item) => (
                          <li
                            key={item.objectID}
                            className="SearchItem"
                            {...this.autocomplete?.getItemProps({
                              item,
                              source
                            })}
                            style={{ display: item.__autocomplete_indexName == indexNameCategory ? "block" : 'none' }}
                          >
                            <a href={item.url} className=" SearchItem-Link ">
                              <h4>Kategorien {item.name}</h4>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
          </ul>
          <button className="Button" id="seeallseach" style={{ display: 'none' }} onClick={this.onSearchAllPress}>Se flere resultater</button>
        </article>
      </div>
    );
  }
}

export default AutocompleteClass;