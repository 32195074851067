/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Meta from './Meta.component';

/** @namespace Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    default_description: state.MetaReducer.default_description,
    default_keywords: state.MetaReducer.default_keywords,
    default_title: state.MetaReducer.default_title,
    canonical_url: state.MetaReducer.canonical_url,
    title_prefix: state.MetaReducer.title_prefix,
    title_suffix: state.MetaReducer.title_suffix,
    description: state.MetaReducer.description,
    keywords: state.MetaReducer.keywords,
    title: state.MetaReducer.title,
    robots: state.MetaReducer.robots,
    status_code: state.MetaReducer.status_code,
    seotweak: state.ConfigReducer.seotweak,
    og_title: state.MetaReducer.title,
    og_description: state.MetaReducer.description,
    og_url: state.MetaReducer.canonical_url,
    og_type: 'business.business',
    product:state.ProductReducer.product,
    category: state.CategoryReducer.category
});

/** @namespace Component/Meta/Container */
export class MetaContainer extends PureComponent {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: PropTypes.string,
        robots: PropTypes.string,
        status_code: PropTypes.string,
        seotweak: PropTypes.object,
        og_title: PropTypes.string,
        og_description: PropTypes.string,
        og_url: PropTypes.string,
        og_type: PropTypes.string,
        product: PropTypes.object,
        category: PropTypes.object
    };

    static defaultProps = {
        default_description: '',
        default_keywords: '',
        default_title: '',
        canonical_url: '',
        title_prefix: '',
        title_suffix: '',
        description: '',
        keywords: '',
        title: '',
        robots: '',
        status_code: '',
        seotweak: {},
        og_title: '',
        og_description: '',
        og_url: '',
        og_type: '',
        product: {},
        category: {}
    };

    containerProps = () => ({
        metadata: this._getMetadata()
    });

    _generateMetaFromMetadata(metadata, param = 'name') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }

    _getTitle() {
        const { title, default_title } = this.props;

        return title || default_title;
    }

    _getDescription() {
        const { description, default_description } = this.props;

        return description || default_description;
    }

    _getKeywords() {
        const { keywords, default_keywords } = this.props;

        return keywords || default_keywords;
    }

    _getRobots() {
        const { robots } = this.props;

        return robots;
    }

    _getStatusCode() {
        const { status_code } = this.props;

        return status_code;
    }

    _getOgLocale() {
        if(this.props.seotweak.general && this.props.seotweak.general.store_locale) {
            return this.props.seotweak.general.store_locale
        }
    }
    _getOgTitle() {
        const { og_title, default_title } = this.props;
        return og_title || default_title;
    }
    _getOgDescription() {
        const { og_description, default_description } = this.props;
        return og_description || default_description;
    }
    _getOgUrl() {
        const { og_url, default_description } = this.props;
        return og_url || default_description;
    }
    _getOgType() {
        let { og_type } = this.props;
        if(Object.keys(this.props.product).length > 0) {
            og_type  = 'product'
        }
        return og_type
    }
    _getOgImage() {
        let image = ''
        if(Object.keys(this.props.product).length > 0) {
			
			if(window.location.pathname == '/') {
				image = this.props.seotweak.shop.shop_image
			} else {
			   image  = this.props.product.small_image.url
			}
			
           
        }else if(this.props.seotweak.shop && this.props.seotweak.shop.shop_image){
            image = this.props.seotweak.shop.shop_image
        }
        return image
    }
    _getBusinessContactDataStreetAddress() {
        let address = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.shop_address) {
            address  = this.props.seotweak.shop.shop_address
        }
        return address
    }
    _getBusinessContactDataLocality() {
        let city = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.shop_city) {
            city  = this.props.seotweak.shop.shop_city
        }
        return city
    }
    _getBusinessContactDataPostalCode() {
        let zip = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.shop_zip) {
            zip  = this.props.seotweak.shop.shop_zip
        }
        return zip
    }
    _getBusinessContactDataCountryName() {
        let country = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.shop_country) {
            country  = this.props.seotweak.shop.shop_country
        }
        return country
    }
    _getPlaceLocationLatitude() {
        let latitude = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.map_lat) {
            latitude  = this.props.seotweak.shop.map_lat
        }
        return latitude
    }
    _getPlaceLocationLongitude() {
        let longitude = ''
        if(Object.keys(this.props.product).length == 0 && this.props.seotweak.shop && this.props.seotweak.shop.map_long) {
            longitude  = this.props.seotweak.shop.map_long
        }
        return longitude
    }
    _getTwitterCard() {
        let card = 'summary_large_image'
        return card
    }
    _getTwitterDescription() {
        let description = ''
        if(Object.keys(this.props.product).length > 0){
            // description = this.removeHtmlTag(this.props.product.description.html)
			
			if(window.location.pathname == '/') {
				 description = this.props.seotweak.shop.shop_desc
			} else {
			   description = this.removeHtmlTag(this.props.product.description.html)
			}
			
        } else if(Object.keys(this.props.category).length > 0) {
            description = this.props.category.description
        }else if(this.props.seotweak.shop && this.props.seotweak.shop.shop_desc){
            description = this.props.seotweak.shop.shop_desc
        }
        return description
    }
    _getTwitterTitle() {
        let title = ''
        if(Object.keys(this.props.product).length > 0){
			if(window.location.pathname == '/') {
				title = this.props.title
			} else {
			  title = this.props.product.name	
			}
            
        } else if(Object.keys(this.props.category).length > 0) {
            title = this.props.category.name
        }else if(this.props.seotweak.shop && this.props.seotweak.shop.shop_desc){
            title = this.props.title
        }
        return title
    }
    _getTwitterSite() {
        let social_twittersite = ''
        if(this.props.seotweak.social_media && this.props.seotweak.social_media.social_twittersite) {
            social_twittersite  = this.props.seotweak.social_media.social_twittersite
        }
        return social_twittersite
    }
    _getTwitterCreator() {
        let social_twittersite = ''
        if(this.props.seotweak.social_media && this.props.seotweak.social_media.social_twittersite) {
            social_twittersite  = this.props.seotweak.social_media.social_twittersite
        }
        return social_twittersite
    }
    removeHtmlTag(stringValue) {
        return stringValue.replace(/<[^>]*>?/gm, '');
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            'og:locale': this._getOgLocale(),
            'og:title': this._getOgTitle(),
            'og:description': this._getOgDescription(),
            'og:url': this._getOgUrl(),
            'og:type': this._getOgType(),
            'og:image': this._getOgImage(),
            'business:contact_data:street_address': this._getBusinessContactDataStreetAddress(),
            'business:contact_data:locality': this._getBusinessContactDataLocality(),
            'business:contact_data:postal_code': this._getBusinessContactDataPostalCode(),
            'business:contact_data:country_name': this._getBusinessContactDataCountryName(),
            'place:location:latitude': this._getPlaceLocationLatitude(),
            'place:location:longitude': this._getPlaceLocationLongitude(),
            'twitter:card': this._getTwitterCard(),
            'twitter:description': this._getTwitterDescription(),
            'twitter:title': this._getTwitterTitle(),
            'twitter:site': this._getTwitterSite(),
            'twitter:creator': this._getTwitterCreator(),
            'twitter:image': this._getOgImage(),
            'twitter:image:alt': this._getTwitterTitle()
        };

        return this._generateMetaFromMetadata(meta);
    }

    render() {
        return (
            <Meta
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

/** @namespace Component/Meta/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
