/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CAT_ATTRIBUTES = 'UPDATE_CAT_ATTRIBUTES';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace Store/Product/Action/updateProductDetails
 */
export const updateCategoryAttributesDetails = (getCategoryAttributesData) => ({
    type: UPDATE_CAT_ATTRIBUTES,
    getCategoryAttributesData
});
